@import "../variables";

.profileCard {
  position: fixed;
  background: #fff;
  border: 1px solid $darker-gray;
  top: 75px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 90vw;
  height: 70vh;
  .profile__details {
    padding: 20px;
  }
  .profile__photoWrapper {
    float: left;
    width: 80px;
  }
  .bioWrapper {
    float: left;
    width: 216px;
    h4 {
      margin: 0;
      width: 80%;
    }
  }
  .profile__details h4 {
    margin: 0;
    width: 80%;
  }
  .bioWrapper span, .profile__details span {
    display: block;
    padding: 4px 0;
  }
  .bio {
    clear: both;
    padding-top: 30px;
    h5 {
      margin: 0;
    }
  }
  .profile__details h5 {
    margin: 0;
  }
  .bio .fullBio, .sessionSummaryFull {
    display: block;
    height: 150px;
    overflow-y: scroll;
  }
  .closeProfileCard {
    position: absolute;
    right: 10px;
    top: 10px;
  }

  &Overlay {
    display: none;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0px;
    left: 0;
    background-color: rgba(0, 0, 0, 0.7);
  }
}

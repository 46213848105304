@import "./variables";

#root > div {
  height: 100vh;
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --mdc-theme-primary: $dark-gray !important;
  --mdc-theme-secondary: $dark-gray !important;
}

header.mdc-top-app-bar {
  background-color: #000;
  top: 0px !important;
}

.event-icon {
  max-width: 60px;
  max-height: 60px;
  padding: 10px;
}

.upgradeNotice {
  position: absolute;
  z-index: 9998;
  bottom: 30px;
  left: 20px;
  text-align: center;
}

.mdc-tab--active .mdc-tab__text-label {
  color: black !important;
}

.mdc-tab-indicator > .mdc-tab-indicator__content--underline {
  background-color: #df0f00 !important;
}

.drawer-app-content {
  overflow: auto;
  -webkit-overflow-scrolling: touch
}

$lightest-gray: #f7f8fb;
$lighter-gray: #f5f0e9;
$light-gray: #e3e8f0;
$medium-gray: #cacaca;
$medium-dark-gray: #a1a7bd;
$dark-gray: #666;
$darker-gray: #333;
$darkest-gray: #040000;
$original-gray: #fafafa;
$nav-gray: #fafbfc;
$bk-gray: #efefef;
$black: black;
$white: white;
$indica: #930d6e;
$sativa: #ed6910;
$warning: #f2ce08;
$alert: #ed6910;
$danger: #cc4b37;
$success: #0bab9d;
$primary: #4596ba;
$secondary: #4397bc;
$darkest-primary: #343e5c;
$purple: #930d6e;
$green: #00853d;
$light-green: #a0ce67;
$dark-green: #0cab9d;
$coral: #e67166;
$jujama-brand: #df0f00;
$link-blue: #0000ff;
$dark-blue: #2d69a0;

$border-color: $dark-gray;

$top-height: 64px;
$top-height-mobile: 56px;
$tabbar-height: 48px;

$drawer-modal-threshold: 500px;
$drawer-zIndex: 6;

$drawer-modal-threshold: 500px;
$app-bar-icon-size: 48px;
$app-bar-icon-spacing: 0px;

.mdc-checkbox,
.mdc-checkbox__checkmark {
  color: rgb(51, 151, 106);
}

button.btn--primary {
  background-color: #000 !important;
  color: #fff !important;
}

@media only screen and (min-width: $drawer-modal-threshold) {
  .mdc-top-app-bar__section--align-start {
    .material-icons[aria-label='menu'] {
      display: none;
    }
  }
  .mdc-top-app-bar__section--align-end {
    .material-icons {
      position: fixed;

      &:first-child {
        right: calc(#{$app-bar-icon-spacing} + #{$app-bar-icon-size} + 10px);
      }
      &:last-child {
        right: 10px;
      }
    }
  }
}
